.login-background {
    background-color: rgba(33, 145, 209, 0.123);
    border-radius: 2vw;
    border: 1px solid rgba(33, 145, 209, 0.897);
}

.login-header {
    font-weight:bold;
    color: rgb(41, 43, 121);
}

.bg-purple {
    background-color: rgba(148, 54, 236, 0.425);
}

.border-1 {
    border: 1px solid black;
}