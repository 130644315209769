.toast-message-container {
    z-index: 10;
    position:absolute;
}

.toast-message {
    border-radius: 1.5vh;
}

.toast-message .toast-header{
    font-weight: 500;
    border-top-left-radius: 1.5vh;
    border-top-right-radius: 1.5vh;
}

.toast-message .toast-body{
    font-weight: bold;
    border-bottom-left-radius: 1vh;
    border-bottom-right-radius: 1vh;
}

.toast-message .btn-close {
    background-color: pink;
}

.toast-info {
    background-color: aqua;
}

.toast-info .btn-close {
    background-color: #6c919b;
}

.toast-info .toast-header {
    background-color: #3a8ea3;
    color: #EEE;
}

.toast-info .toast-body {
    background-color:rgb(135, 168, 187);
    color: #EFEFEF;
}

.toast-error {
    background-color: pink;
}

.toast-error .toast-header {
    background-color: #a33a3a;
    color: #EEE;
}

.toast-error .toast-body {
    background-color:rgb(235, 14, 14);
    color: #EFEFEF;
}

.toast-warning {
    background-color: rgb(148, 147, 85);
}

.toast-warning .btn-close {
    background-color:rgba(158, 157, 62, 0.575)
}

.toast-warning .toast-header {
    background-color: #eaff00;
    color: rgb(75, 75, 75);
}

.toast-warning .toast-body {
    background-color:rgb(222, 228, 153);
    color: #575757;
}

.toast-success {
    background-color: rgb(67, 167, 0);
}

.toast-success .btn-close {
    background-color: #9fcf95;
}

.toast-success .toast-header {
    background-color: #47e228;
    color: rgb(75, 75, 75);
}

.toast-success .toast-body {
    background-color:rgb(159, 228, 153);
    color: #575757;
}